import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class IssuesService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.ISSUES(), data);
  }

  detail(unique_id, data): Observable<any> {
    return this.getRequest(AppUrl.ISSUE_DETAILS(unique_id), data);
  }

  submit(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.SUBMIT_ISSUES(unique_id), data);
  }

  update(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUES(unique_id), data, true);
  }
}
