<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-12 main-header">
        <h2>{{title}}</h2>
        <!--<h6 class="mb-0">admin panel</h6>-->
      </div>
      <!--<div class="col-lg-6 breadcrumb-right">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)" [routerLink]="'/general/default'">
              <i class="pe-7s-general"></i>
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
          <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
          </li>
        </ol>
      </div>-->
    </div>
  </div>
</div>
