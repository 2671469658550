import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../shared/services/alert.service';
import {IssuesService} from '../../shared/services/issues.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from '../../shared/services/file.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'ps-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  generateTicketForm: FormGroup;
  submitted: any = false;
  issue: any = [];
  unique_id: any;
  fileUploadSupportiveDocumentData: any;
  onFileChanged: any;
  showTracker: any = false;
  environmentUrl: any = environment.appUrl;

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              private issuesService: IssuesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fileService: FileService) {
    this.unique_id = this.activatedRoute.snapshot.params['unique_id'];
  }

  ngOnInit(): void {
    this.refresh();
    this.detail();
  }

  refresh() {
    this.generateTicketForm = this.fb.group({
      'name': [''],
      'enrollment_no': [''],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'father_name': ['', Validators.required],
      'address': ['', Validators.required],
      'remarks': ['']
    });
  }

  detail(params: any = {}) {
    this.issuesService.detail(this.unique_id, params).subscribe(data => {
      if (data) {
        if (data.created_at) {
          data['created_at'] = data.created_at.split(' ')[0];
        }
        this.issue = data;
        if (this.issue) {
          this.generateTicketForm.patchValue({
            'enrollment_no': (this.issue && this.issue.enrollment_no) ? this.issue.enrollment_no : '',
            'email': (this.issue && this.issue.email) ? this.issue.email : '',
            'contact': (this.issue && this.issue.contact) ? this.issue.contact : '',
            'father_name': (this.issue && this.issue.father_name) ? this.issue.father_name : '',
            'address': (this.issue && this.issue.address) ? this.issue.address : '',
            'remarks': (this.issue && this.issue.remarks) ? this.issue.remarks : '',
          });
          if (this.issue && this.issue.supportive_document && this.issue.supportive_document.url) {
            this.fileUploadSupportiveDocumentData = this.issue.supportive_document;
          }
        }
        if (this.issue && this.issue['payment_status'] && this.issue['edit_status'] && this.issue['payment_status'] === 'PENDING' && this.issue['edit_status'] === 'DISALLOW' && this.issue['amount'] != 0) {
          this.router.navigateByUrl('/');
        }
        if (this.issue && this.issue['payment_status'] && this.issue['edit_status'] && this.issue['payment_status'] === 'SUCCESS' && this.issue['edit_status'] === 'DISALLOW') {
          this.showTracker = true;
        }
        if (this.issue && this.issue['status'] && this.issue['edit_status'] && this.issue['amount'] == 0 && this.issue['edit_status'] === 'DISALLOW') {
          this.showTracker = true;
        }
      }
    }, error => {
      this.router.navigateByUrl('/');
    });
  }

  goTo(url) {
    window.open(url, '_blank');
  }

  paymentUrl(url) {
    window.location.replace(url);
  }

  submit() {
    this.submitted = true;
    if (!this.generateTicketForm.valid) {
      return;
    }
    if (!this.fileUploadSupportiveDocumentData || !this.fileUploadSupportiveDocumentData.id) {
      this.alertService.showErrors('Please upload ticket supportive document');
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().name) ? this.generateTicketForm.getRawValue().name : '',
        'enrollment_no': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().enrollment_no) ? this.generateTicketForm.getRawValue().enrollment_no : '',
        'email': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().email) ? this.generateTicketForm.getRawValue().email : '',
        'contact': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().contact) ? this.generateTicketForm.getRawValue().contact : '',
        'address': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().address) ? this.generateTicketForm.getRawValue().address : '',
        'remarks': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().remarks) ? this.generateTicketForm.getRawValue().remarks : '',
        'father_name': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().father_name) ? this.generateTicketForm.getRawValue().father_name : '',
        'supportive_document': (this.fileUploadSupportiveDocumentData && this.fileUploadSupportiveDocumentData.id) ? this.fileUploadSupportiveDocumentData.id : ''
      };
      this.issuesService.submit(this.unique_id, params).subscribe(data => {
        this.submitted = false;
        this.showTracker = true;
      }, error => {
        this.alertService.showErrors('Something went wrong, please try again later');
        this.submitted = false;
      });
    }
  }

  payNow() {
    this.submitted = true;
    if (!this.generateTicketForm.valid) {
      return;
    }
    if (!this.fileUploadSupportiveDocumentData || !this.fileUploadSupportiveDocumentData.id) {
      this.alertService.showErrors('Please upload ticket supportive document');
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().name) ? this.generateTicketForm.getRawValue().name : '',
        'enrollment_no': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().enrollment_no) ? this.generateTicketForm.getRawValue().enrollment_no : '',
        'email': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().email) ? this.generateTicketForm.getRawValue().email : '',
        'contact': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().contact) ? this.generateTicketForm.getRawValue().contact : '',
        'address': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().address) ? this.generateTicketForm.getRawValue().address : '',
        'remarks': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().remarks) ? this.generateTicketForm.getRawValue().remarks : '',
        'father_name': (this.generateTicketForm && this.generateTicketForm.getRawValue() && this.generateTicketForm.getRawValue().father_name) ? this.generateTicketForm.getRawValue().father_name : '',
        'amount': (this.issue) ? this.issue.amount : 0,
        'supportive_document': (this.fileUploadSupportiveDocumentData && this.fileUploadSupportiveDocumentData.id) ? this.fileUploadSupportiveDocumentData.id : ''
      };
      this.issuesService.update(this.unique_id, params).subscribe(data => {
        if (data) {
          this.paymentUrl(data.url);
        } else {
          this.alertService.showErrors('Something went wrong, please try again later');
        }
      }, error => {
        this.alertService.showErrors('Something went wrong, please try again later');
        this.submitted = false;
      });
    }
  }


  onFileChange(e, type) {
    this.onFileChanged = e;
    this.fileService.fileUploadIssue({'type': type, 'fileType': 'NORMAL', 'user_id': 1}, this.onFileChanged).subscribe(data => {
      if (type === 'SUPPORTIVE_DOCUMENT') {
        this.fileUploadSupportiveDocumentData = data['data'];
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
  }

  openUrl(url) {
    window.open(url, '_blank');
  }
}
