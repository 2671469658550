import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ContentComponent} from './shared/components/layout/content/content.component';
import {content} from './shared/routes/routes';
import {full} from './shared/routes/full.routes';
import {LoginComponent} from './auth/login/login.component';
import {TicketsComponent} from './components/tickets/tickets.component';
import {TicketResponseComponent} from './components/tickets/ticket-response/ticket-response.component';
import {PaymentReponseComponent} from "./components/payment-response/payment-reponse.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'general/home',
    pathMatch: 'full'
  },
  {
    path: 'tickets/:unique_id',
    component: TicketsComponent
  },
  {
    path: 'tickets/response/:type/:unique_id',
    component: TicketResponseComponent
  },
  {
    path: 'payment/:payment_for/:type/:unique_id',
    component: PaymentReponseComponent
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: '',
    component: ContentComponent,
    children: content
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
