<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <div class="card ribbon-wrapper shadow-0 border p-0">
        <div class="card-body notification-padding">
          <div class="ribbon ribbon-success ribbon-vertical-left"><i class="fa fa-bell"></i></div>
          <h4>New Notification</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled.</p>
        </div>
      </div>
      <div class="card ribbon-vertical-left-wrapper shadow-0 border p-0">
        <div class="card-body notification-padding">
          <div class="ribbon ribbon-secondary ribbon-vertical-left"><i class="fa fa-bell"></i></div>
          <h4>University Notification</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled.</p>
        </div>
      </div>
      <div class="card ribbon-wrapper shadow-0 border p-0">
        <div class="card-body notification-padding">
          <div class="ribbon ribbon-warning ribbon-vertical-left"><i class="fa fa-bell"></i></div>
          <h4>Center Important Notification</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled.</p>
        </div>
      </div>
    </div>
  </div>
</div>
