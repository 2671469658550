<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <ng-template #content let-modal>
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="modalType === 'PASSWORD'">Update Password</h5>
          <h5 class="font-weight-400" *ngIf="modalType === 'PROFILE_IMAGE'">
            <i class="fab fa-pagelines cursor-pointer mr-2"></i>
            <span>Update Profile Image</span>
          </h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" *ngIf="modalType === 'PROFILE_IMAGE'">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="row" *ngIf="imageChangedEvent">
                <div class="text-center col-md-12">
                  <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="20 / 20"
                    [resizeToWidth]="200"
                    format="png, jpeg, jpg"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()">
                  </image-cropper>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="text-center mt-2 mb-2">
                    <img [src]="profileImageUploadArea" class="img-fluid width-85-per" alt="Slide 1"
                         (click)="imageProfileUpload.click()" *ngIf="!imageChangedEvent"/>
                    <input class="form-control-file display-hidden" type="file"
                           (change)="onImageChange($event)" #imageProfileUpload>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                  <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                          *ngIf="imageChangedEvent" (click)="saveProfileImage()">
                    <i class="fa fa-save mr-1"></i> Save
                  </button>
                  <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                          (click)="imageProfileUpload.click()">New Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="modalType === 'PASSWORD'">
            <div class="col-sm-12">
              <form class="theme-form" [formGroup]="profilePasswordForm">
                <div class="row">
                  <div class="col-12">
                    <label class="col-form-label d-flex">
                      Current Password
                      <i (click)="inputTypePasswordOld = 'text'"
                         [ngClass]="{'display-hidden': (inputTypePasswordOld === 'text')}"
                         class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                      <i (click)="inputTypePasswordOld = 'password'"
                         [ngClass]="{'display-hidden': (inputTypePasswordOld === 'password')}"
                         class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                    </label>
                    <input class="form-control" [type]="inputTypePasswordOld" formControlName="current_password"/>
                    <small *ngIf="submitted && profilePasswordForm.get('current_password').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </div>
                  <div class="col-12 mt-10px">
                    <label class="col-form-label d-flex">
                      New Password
                      <i (click)="inputTypePasswordNew = 'text'"
                         [ngClass]="{'display-hidden': (inputTypePasswordNew === 'text')}"
                         class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                      <i (click)="inputTypePasswordNew = 'password'"
                         [ngClass]="{'display-hidden': (inputTypePasswordNew === 'password')}"
                         class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                    </label>
                    <input class="form-control" [type]="inputTypePasswordNew" formControlName="new_password"/>
                    <small *ngIf="submitted && profilePasswordForm.get('new_password').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </div>
                  <div class="col-12 mt-10px">
                    <label class="col-form-label d-flex">
                      Confirm Password
                      <i (click)="inputTypePasswordConfirm = 'text'"
                         [ngClass]="{'display-hidden': (inputTypePasswordConfirm === 'text')}"
                         class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                      <i (click)="inputTypePasswordConfirm = 'password'"
                         [ngClass]="{'display-hidden': (inputTypePasswordConfirm === 'password')}"
                         class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                    </label>
                    <input class="form-control" [type]="inputTypePasswordConfirm" formControlName="confirm_password"/>
                    <small *ngIf="submitted && profilePasswordForm.get('confirm_password').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer d-lg-block">
          <button class="btn btn-success mr-1 btn-pill pull-right" *ngIf="modalType === 'PASSWORD'"
                  (click)="updatePassword()">
            <i class="fa fa-user mr-2"></i>Update
          </button>
        </div>
      </ng-template>
      <div class="card">
        <div class="card-header padding-25">
          <div class="row">
            <div class="col-12">
              <h5>Profile Info</h5>
            </div>
          </div>
        </div>
        <div class="card-body row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-sm-12">
                <form class="theme-form" [formGroup]="addProfileForm">
                  <div class="row">
                    <div class="col-12 avatar-showcase m-t-10 m-b-20 text-center">
                      <!--<div class="avatars text-center">
                        <div class="avatar">
                          <img class="img-100 rounded-circle" src="assets/images/user/10.jpg" alt="#">
                        </div>
                      </div>-->
                      <i class="fas fa-user-circle font-large-5 font-weight cursor-pointer" style="color: #6e6b7b !important;"
                         *ngIf="!profileImage"></i>
                      <img [src]="profileImage" class="cursor-pointer profile-image"
                           *ngIf="profileImage"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="name">Name</label>
                        <input class="form-control" id="name" placeholder="Enter Name" formControlName="name" disabled>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="username">Username</label>
                        <input class="form-control" id="username" placeholder="Enter username" formControlName="username" disabled>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="email">Email</label>
                        <input class="form-control" id="email" placeholder="Enter Email" formControlName="email" disabled>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label pt-0" for="contact">Contact</label>
                        <input class="form-control" id="contact" placeholder="Enter Contact" formControlName="contact" disabled>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="card-footer d-lg-block padding-25">
          <button class="btn btn-secondary mr-1 pull-right" (click)="openLg(content)"><i class="fa fa-key m-r-5"></i>
            Update Password
          </button>
        </div>-->
      </div>
    </div>
  </div>
</div>
