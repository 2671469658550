import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'lms',
    loadChildren: () => import('../../components/lms/lms.module').then(m => m.LmsModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('../../components/exam/exam.module').then(m => m.ExamModule)
  },
  /*{
    path: 'lms',
    loadChildren: () => import('../../components/lms/lms.module').then(m => m.LmsModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('../../components/exam/exam.module').then(m => m.ExamModule)
  },*/
  {
    path: 'docs',
    loadChildren: () => import('../../components/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'general',
    loadChildren: () => import('../../components/general/general.module').then(m => m.GeneralModule)
  },
 /* {
    path: 'locker',
    loadChildren: () => import('../../components/locker/locker.module').then(m => m.LockerModule)
  },*/
  {
    path: 'support',
    loadChildren: () => import('../../components/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'notify',
    loadChildren: () => import('../../components/notify/notify.module').then(m => m.NotifyModule)
  },
  /*{
    path: 'ir',
    loadChildren: () => import('../../components/issues-receipt/issues-receipt.module').then(m => m.IssuesReceiptModule)
  }*/
];

