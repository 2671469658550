import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../shared/services/storage.service';
import {AlertService} from "../../shared/services/alert.service";
import {FileService} from "../../shared/services/file.service";
import {ProfileService} from "../../shared/services/profile.service";
import {StudentService} from "../../shared/services/student.service";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {Router} from "@angular/router";

@Component({
  selector: 'ps-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  addProfileForm: FormGroup;
  imageChangedEvent: any;
  croppedImage: any;
  croppedImageEvent: any;
  profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
  profileImage: any;
  profilePasswordForm: FormGroup;
  submitted: any = false;
  inputTypePasswordOld: any = 'password';
  inputTypePasswordNew: any = 'password';
  inputTypePasswordConfirm: any = 'password';

  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,
              private alertService: AlertService,
              private fileService: FileService,
              private profileService: ProfileService,
              private studentService: StudentService,
              private router: Router,
              private fb: FormBuilder) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  modalType: any;
  user: any;

  ngOnInit(): void {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.profilePasswordForm = this.fb.group({
      'current_password': ['', Validators.required],
      'new_password': ['', Validators.required],
      'confirm_password': ['', Validators.required],
    });
    this.addProfileForm = this.fb.group({
      name: [this.user.name, Validators.required],
      code: [this.user.username, Validators.required],
      username: [this.user.username, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      contact: [this.user.contact, Validators.required],
    });
    this.getDetails();
  }

  update() {
    this.alertService.showSuccess({title: 'Success', message: 'Profile updated successfully'});
  }

  openLg(content) {
    this.modalType = 'PASSWORD';
    this.modalService.open(content, {size: 'lg'});
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onImageChange(e) {
    this.imageChangedEvent = e;
  }

  openProfileModel(content) {
    this.modalType = 'PROFILE_IMAGE';
    this.modalService.open(content);
  }

  saveProfileImage() {
    const params = {
      'type': 'PROFILE_IMAGE',
      'user_id': this.user.user_id,
      'fileType': 'BASE64'
    };
    this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
      if (data) {
        this.modalService.dismissAll('');
        this.imageChangedEvent = undefined;
        this.updateProfileImage({'user_id': this.user.user_id, 'profile_image': data['data'].id});
      }
    });
  }

  updateProfileImage(data) {
    this.profileService.updateProfile(this.user.user_id, data).subscribe(data => {
      this.getDetails();
    });
  }

  getDetails() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        if (data && !data.enrollment_no) {
          this.router.navigateByUrl('/general/home');
        }
        if (data && data['student_photograph'] && data['student_photograph'].url) {
          this.profileImage = data['student_photograph'].url;
        }
      }
    });
  }

  updatePassword() {
    this.submitted = true;
    if (!this.profilePasswordForm.valid) {
      return;
    }
    if (this.profilePasswordForm.value.new_password === this.profilePasswordForm.value.confirm_password) {
      if (this.submitted) {
        const params = {
          'newPassword': (this.profilePasswordForm && this.profilePasswordForm.getRawValue() && this.profilePasswordForm.getRawValue().new_password) ? this.profilePasswordForm.getRawValue().new_password : '',
          'currentPassword': (this.profilePasswordForm && this.profilePasswordForm.getRawValue() && this.profilePasswordForm.getRawValue().current_password) ? this.profilePasswordForm.getRawValue().current_password : '',
        };
        this.profileService.updatePassword(params).subscribe(data => {
          this.submitted = false;
          this.profilePasswordForm.patchValue({
            'new_password': '',
            'confirm_password': '',
            'current_password': ''
          });
          this.modalService.dismissAll('');
        }, error => {
          if (error && error['error'] && error['error']['errorDetails']) {
            this.alertService.showErrors(error['error']['errorDetails']);
          } else {
            this.alertService.showErrors('Something went wrong, please try again');
          }
          this.submitted = false;
        });
      }
    } else {
      this.submitted = false;
      this.alertService.showErrors('Password and Confirm Password didn\'t match');
    }
  }
}
