import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {IssuesService} from "../../../shared/services/issues.service";

@Component({
  selector: 'ps-ticket-response',
  templateUrl: './ticket-response.component.html',
  styleUrls: ['./ticket-response.component.scss']
})
export class TicketResponseComponent implements OnInit {
  uniqueId: any;
  type: any;
  transactionType: any;
  environmentUrl: any = environment.appUrl;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private issueService: IssuesService) {
  }

  ngOnInit(): void {
    this.uniqueId = this.activatedRoute.snapshot.params['unique_id'];
    this.type = this.activatedRoute.snapshot.params['type'];
    if (this.type === 'success') {
      this.transactionType = 'SUCCESS';
    } else if (this.type === 'failed') {
      this.transactionType = 'FAILED';
    } else {
      this.transactionType = 'CANCELLED';
    }
    this.getIssueDetail();
  }

  getIssueDetail() {
    this.issueService.detail(this.uniqueId, {}).subscribe(data => {
      if (!data) {
        this.router.navigateByUrl('auth/login');
      }
    }, error => {
      this.router.navigateByUrl('auth/login');
    });
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  openUrl(url) {
    window.open(url, '_blank');
  }
}
