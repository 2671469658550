import {Component, OnInit} from '@angular/core';
import {StudentService} from "../../shared/services/student.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ps-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  user: any;
  student: any;

  constructor(private studentService: StudentService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getStudentDetails();
  }

  getStudentDetails() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.student = data;
        if (this.student && !this.student.enrollment_no) {
          this.router.navigateByUrl('/general/home');
        }
      }
    });
  }
}
